@tailwind base;
@tailwind components;
@tailwind utilities;

body {
box-sizing: border-box;
background-color: rgb(27, 27, 27)!important;

/* background-color: rgb(9, 9, 9)!important; */
overflow-x: hidden;
width: 100%;
padding: 0;
min-width: 390px;
}

html, body {
  overscroll-behavior: none;
}

html {
  padding: 0;
}

.App {
  text-align: center;
  max-width: 2400px;
  margin: 0 auto; 
}

.relative {
position: relative;
height: 100vh;

}
.navbar {
  display: flex;
  height: 100px;
  width:100%;
  justify-content:space-between;
  align-items:center;
  position:sticky;
  top:0;
z-index: 10;

}
.intro {
  position: relative;
  background-color: transparent!important;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 100px);
  padding-bottom: 100px;
    z-index: 10;

  /* position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  */

}
.spline-bg{
  position: absolute;
  top: 0;
  display: flex;
  height: 100vh;
  width: 100%;
}

.about {
  background-color: #111111;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: justify;
  color: #a6a6a6;
  padding: 100px 15%;
}

.custom_btn {
  background-color: transparent;
  align-items: center;
  justify-content: center;
font-size: 14px;
  padding: 10px;
  border: 1px solid rgb(107, 157, 172);
  border-radius: 5px;
  color: rgb(107, 157, 172);
  cursor: pointer;
&:hover {
  background-color: rgb(91, 135, 148);
  color: black;
}
transition: all linear 0.3s;

}

.skills {
  background-color: rgb(27, 27, 27);
  align-content: center;
  justify-content: space-between;
  text-align: justify;
  color: #090909;
  padding: 100px 0;
  position: relative;
}

.swipe {
  position: absolute;
  justify-content: center;
  width: 100%;
  top: 500px;
  z-index: 5;
}

.spline {
  display: flex;
  height: 500px;
}


.resume {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #a6a6a6;
  align-content: center;
  justify-content: center;
  text-align: justify;
  color: #404040;
  padding-top: 100px;
  padding-bottom: 100px;
}

.projects {
  background-color: #4d4d4d;
  align-content: center;
  justify-content: space-between;
  text-align: justify;
  color: #090909;
  padding-top: 100px;
  padding-bottom: 100px;
}

.projectLayout {
  padding: 15px;
  border-radius: 10px;
  background-color:#404040;
  color: #a6a6a6;
  /* filter: drop-shadow(0px 0px 10px rgb(110, 147, 158)); */


  
  &:hover {
    background-color:#232323;
    /* filter: drop-shadow(0px 0px 10px rgb(110, 147, 158)); */
  }
}

.form {
  background-color: rgb(9, 9, 9);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 100px 15%;
}

form {
  width: 90%;
  max-width: 600px;
}

.login {
display: flex;
flex-direction: column;
}

input {
height: 45px;
padding: 5px;
color: rgb(107, 157, 172);
background-color: transparent;
padding: 15px;
margin: 10px 0;
border: 1px solid rgb(98, 98, 98);
border-radius: 5px;
outline:transparent!important;


&:focus {
  border: 1px solid rgb(107, 157, 172)
}
}

.text-aria {
  display: flex;
  flex-direction: column;
}
textarea {
height: 200px;
background-color: #1a1a1a;
border-bottom: 1px solid gray;
margin: 10px 0;
outline: none;
padding: 10px;
}

.lable {
font-size: 14px;
}

.submit {
  height: 50px;
  background-color: transparent;
  margin: 10px 0;
  border: 1px solid gray;
  color: gray;
  cursor: pointer;
&:hover {
  background-color: rgb(107, 157, 172);
  color: black;
}
transition: all linear 0.3s;
}

.skillTag {
   border: 1px solid rgb(62, 62, 62)!important;
  color: gray!important;
border-radius: 50px!important;
background-color:transparent!important;
}
.toolTag {
  border: 1px solid rgb(107, 157, 172);
  color: gray!important;
border-radius: 50px!important;
background-color:transparent!important;
}
.contributions {
  background-color: rgb(9, 9, 9);

}

/* //coinflip// */

.coinflip {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}
.flip {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 25px;
  left: 25px;
  transition-timing-function: ease-out;
}

.top {
  z-index: 5;
  backface-visibility: hidden;
}

.coinImg {
  width: 100px;
}

.bottom {
  z-index: 3;
}

.coin {
  width: 150px;
  height: 150px;
  position: relative;
  background-color: transparent;
  z-index: 4;
  margin: 0 100px;
}

.btn {
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #88beb1;
  color: white;
  border: 3px solid #487e5e;
}

.result {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #88beb1;
  width: 100px;
}

.coinflipmodal {
  background-color: red;
}

.modal {
  position: absolute;
  background-color: #d0d0d0;
  height: 70vh;
  width: 60%;
  padding: 2%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}